body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  background: #fff url("/images/background.png");
}

main {
  max-width: 1100px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #dee2e6;
}

#Sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color: white;
}

.offcanvas-top {
  height: fit-content;
}

hr {
  background-color: rgba(0, 0, 0, .125);
  opacity: 1;
}

select {
  width: auto !important;
}

#WorkflowAndTaskIds {
  font-size: 0.6em;
  opacity: 0.5;
}

#LoginPage main {
  margin-top: 50px;
  max-width: 700px;
}

#LoginPage #LoginForm {
  max-width: 380px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

#LoginPage #LoginForm div{
  text-align:  center;
}

#LoginPage img{
  max-height: 55px;
}

#MilestonesSidebar.list-group-flush{
  border-radius: 3px;
}

#MilestonesSidebar .list-group-item-dark{
  background-color: #e9ecef;
}

#MilestonesSidebar .progress{
  height: 6px;
  background-color: white;
}

#DatasetListView .list-group{
  max-height: 234px;
  overflow-y: scroll;
}

.CheckboxWithLabel .field-with-icon{
  width: 1.5em;
}

#ContactUsPage main {
  max-width: 550px;
}

#WorkflowComponent.loading{
  cursor: progress;
}

iframe{
  width: 100%;
  min-height: 600px;
  border-radius: 3px;
}
